import * as R from "remeda";
import { AudioRecord } from "@/API";
import { AudioRecordToSrt } from "@/Helper/AudioRecordHelper";
import { TextSaveToFile } from "@/Helper/SaveToFile";
import { Button, Center, Checkbox, ComboboxItem, Group, Modal, MultiSelect, Switch } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { PropsWithChildren, useMemo } from "react";
import { languageCodeData } from "@/Helper/languageCodeData";
import { notifications } from "@mantine/notifications";
import { color } from "motion/react";

export function ExportAudioRecordTextButton(props: { audioRecord: AudioRecord } & PropsWithChildren) {
    const [opened, { open, close }] = useDisclosure(false);
    const form = useForm({
        initialValues: {
            languages: [],
            exportSource: true,
        }
    });
    var translatedTexts: ComboboxItem[] = useMemo(() => {
        var data = R.uniqueBy(R.flatMap(props.audioRecord.recordEntries ?? [], x => x.translatedTexts).map<ComboboxItem>(x => {
            return {
                label: languageCodeData[x?.language!]?.name ?? "",
                value: x?.language!
            }
        }), x => x.value).filter(x => !!x.value)
        data = data.sort((a, b) => {
            var a1 = Object.keys(languageCodeData).findIndex(x => x == a.value) ?? -1
            var b1 = Object.keys(languageCodeData).findIndex(x => x == b.value) ?? -1
            return a1 - b1
        })
        return data;
    }, [props.audioRecord])
    return <>
        <Center onClick={() => {
            form.reset()
            open()
        }}>
            {props.children}
        </Center>
        <Modal opened={opened} onClose={close} title="輸出文字">
            <form onSubmit={form.onSubmit((values) => {
                if (!values.exportSource && values.languages.length == 0) {
                    notifications.show({ message: "至少選擇來源文字或翻譯文字", color: "yellow" })
                    return
                }
                var srt = AudioRecordToSrt(props.audioRecord, values.languages, values.exportSource)
                if (srt) TextSaveToFile(`${props.audioRecord.name}.txt`, srt)
                close()
            })}>
                <Switch
                    label="輸出來源文字"
                    {...form.getInputProps('exportSource', { type: 'checkbox' })}
                />
                <MultiSelect
                    label="輸出翻譯文字"
                    searchable
                    clearable
                    data={translatedTexts}
                    {...form.getInputProps('languages')} />
                <Group justify="flex-end" mt="md">
                    <Button type="submit">輸出</Button>
                </Group>
            </form>
        </Modal>
    </>
}