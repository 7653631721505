import { AudioRecord } from "@/API";

export function AudioRecordToSrt(record: AudioRecord, languages: string[] = [], exportSource: boolean = true) {
    if (!record.recordEntries) return ""
    let srtOutput = '';
    let index = 1;

    for (const entry of record.recordEntries) {
        const { time, duration, text, translatedTexts } = entry;

        if (time !== undefined && duration !== undefined && text) {
            const startTime = formatTime(time);
            const endTime = formatTime(time + duration);

            srtOutput += `${index}\n`;
            srtOutput += `${startTime} --> ${endTime}\n`;
            if (exportSource)
                srtOutput += `${text}\n`;
            for (const translatedText of (translatedTexts ?? [])) {
                if (translatedText.language && languages.indexOf(translatedText.language) >= 0) {
                    srtOutput += `${translatedText.text}\n`;
                }
            }
            srtOutput += `\n`;

            index++;
        }
    }

    return srtOutput.trim();
}

function formatTime(seconds: number): string {
    const totalMilliseconds = seconds * 1000; // Convert to milliseconds
    const totalSeconds = Math.floor(totalMilliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
    const ms = Math.floor(totalMilliseconds % 1000);

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')},${String(ms).padStart(3, '0')}`;
}